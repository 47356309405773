import React from 'react'

const TestimonialsMachine: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      width="96"
      height="118"
      viewBox="0 0 96 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4798_17084)">
        <path
          d="M94.4193 44.97H94.4059L94.0687 46.4593L91.4246 42.5177L87.3459 44.3865L84.7017 40.4468L80.6231 42.3213L77.9808 38.3816L73.9021 40.258L71.2598 36.3164L67.1812 38.1928L64.5465 34.2569L60.4679 36.1333L57.8256 32.1918L53.7488 34.0682L53.7203 34.1673L53.6803 34.154C55.6539 30.6739 56.7969 29.074 57.8466 26.3147C57.8866 26.1964 57.9285 26.0839 57.9742 25.9695C58.0771 25.6797 58.1818 25.3974 58.279 25.119C58.3323 24.9665 58.3819 24.8196 58.4314 24.6709C58.5533 24.3162 58.6638 23.9711 58.7667 23.6278C58.82 23.4524 58.8715 23.2789 58.9191 23.1053C58.9667 22.9318 59.0162 22.7659 59.0582 22.6C59.161 22.2186 59.2487 21.8468 59.3363 21.4806C59.3725 21.3262 59.4106 21.1736 59.4373 21.023C60.0221 18.2541 60.0735 15.8743 59.7021 13.7767C59.6773 13.6165 59.6449 13.4602 59.6125 13.3095C59.5801 13.1589 59.5458 13.0025 59.5077 12.8538C59.3298 12.1208 59.0915 11.4039 58.7953 10.7104C58.6771 10.4377 58.5552 10.1688 58.4238 9.90759C58.0253 9.11533 57.5607 8.35814 57.035 7.64409C56.8636 7.4 56.6807 7.16545 56.4902 6.93281C56.3968 6.81458 56.2997 6.69635 56.2025 6.58194C55.7167 6.00986 55.1948 5.45114 54.6385 4.90576L85.8352 14.0208C85.9114 14.038 100.776 18.6641 94.4193 44.97Z"
          fill="#BCC8D6"
        />
        <path
          d="M57.84 26.3151C56.7903 29.0725 93.9727 22.2667 93.9727 22.2667C91.0199 15.6326 85.882 14.0365 85.8363 14.0269L54.6415 4.91382C55.1996 5.4611 55.7197 6.01792 56.2055 6.5919C56.3027 6.70632 56.396 6.82454 56.4932 6.94277C56.6837 7.17287 56.8659 7.40997 57.0399 7.65405C57.5653 8.36763 58.0299 9.12416 58.4287 9.91565C58.5601 10.1769 58.682 10.4458 58.7982 10.7185C59.0945 11.4126 59.3328 12.1302 59.5107 12.8637C59.5488 13.0106 59.5812 13.1631 59.6155 13.3195C59.6498 13.4759 59.6803 13.6265 59.705 13.7848C60.0765 15.8824 60.027 18.2603 59.4402 21.031C59.4136 21.1798 59.3774 21.3342 59.3393 21.4868C59.2555 21.8548 59.164 22.2286 59.0611 22.608C59.0192 22.7739 58.9716 22.9437 58.9221 23.1134C58.8725 23.2831 58.823 23.4604 58.7716 23.634C58.6687 23.9772 58.5563 24.3243 58.4344 24.679C58.3849 24.8258 58.3372 24.9745 58.282 25.1271C58.1867 25.4055 58.0801 25.6858 57.9772 25.9776C57.9315 26.0901 57.8914 26.2045 57.8495 26.3227L57.84 26.3151Z"
          fill="#20252A"
        />
        <path
          d="M21.8116 111.382L39.3264 117.765C40.2206 118.09 41.2043 118.07 42.0839 117.706C42.9635 117.343 43.6754 116.663 44.0795 115.8L55.5763 91.2718L55.9573 90.4519L64.4213 72.3934L69.1839 62.2353C72.0224 56.9703 79.4482 48.9765 82.8506 43.559C86.4149 36.9496 84.1231 29.7034 77.7432 27.3789L55.9478 19.4385L33.1008 80.9783L21.8116 111.382Z"
          fill="#009420"
        />
        <path
          d="M48.7945 73.2581L54.9668 92.5598L44.0719 115.797C43.6675 116.659 42.9558 117.338 42.0767 117.701C41.1975 118.064 40.2145 118.085 39.3208 117.76L21.8136 111.381L48.7945 73.2581Z"
          fill="#20252A"
        />
        <path
          d="M34.7673 112.048L67.0918 23.497L43.6104 14.9426C37.7982 12.824 30.4658 16.5673 28.1397 22.8372L0.229172 99.4569C0.0578079 99.9282 -0.0189819 100.429 0.00320435 100.93C0.0253906 101.431 0.146111 101.923 0.358459 102.377C0.570808 102.832 0.870605 103.24 1.24069 103.578C1.61078 103.916 2.04389 104.178 2.51521 104.348L29.8866 114.323C30.8353 114.668 31.882 114.623 32.7971 114.196C33.7122 113.77 34.4208 112.997 34.7673 112.048Z"
          fill="#0AF53D"
        />
        <path
          d="M63.1995 22.0742L78.2188 27.5451C72.4065 25.4285 65.0741 29.1717 62.748 35.4417L60.3401 27.3831L63.1995 22.0742Z"
          fill="#0AF53D"
        />
        <path
          d="M29.9825 110.226L5.02256 101.132C4.03389 100.772 3.52413 99.678 3.88398 98.6883L9.15834 84.1828C9.51818 83.1932 10.6114 82.6829 11.6 83.0431L36.5599 92.1367C37.5486 92.4969 38.0584 93.5912 37.6985 94.5809L32.4242 109.086C32.0643 110.076 30.9711 110.586 29.9825 110.226Z"
          fill="#20252A"
        />
        <path
          d="M37.4145 89.1004L12.8931 80.1665C11.7829 79.762 11.2104 78.5331 11.6145 77.4218L24.043 43.2411C24.4471 42.1297 25.6747 41.5567 26.785 41.9612L51.3063 50.895C52.4166 51.2996 52.9891 52.5284 52.585 53.6398L40.1565 87.8205C39.7524 88.9319 38.5247 89.5049 37.4145 89.1004Z"
          fill="#20252A"
        />
        <path
          d="M29.7583 70.9089C28.0942 70.2854 26.7814 69.0327 26.0555 67.3821C25.8578 66.9311 26.0596 66.3925 26.5061 66.1793C26.9514 65.9693 27.4754 66.1655 27.676 66.6177C28.2067 67.8286 29.1723 68.7488 30.3958 69.2072C31.6193 69.6656 32.9518 69.6064 34.1477 69.0423C34.593 68.8322 35.1169 69.0285 35.3176 69.4806C35.5152 69.9316 35.3134 70.4703 34.8669 70.6834C33.234 71.4537 31.4223 71.5323 29.7583 70.9089Z"
          fill="#EFF4F8"
        />
        <path
          d="M37.5159 60.8875C35.5661 60.157 33.4461 61.1217 32.6974 63.1202L31.9027 65.2412C31.7112 65.7523 31.9613 66.3171 32.4584 66.5034C32.9556 66.6897 33.5152 66.4282 33.7067 65.9171L34.5254 63.7319C34.9164 62.6882 35.9855 62.2721 36.8726 62.6044C37.7597 62.9368 38.3696 63.982 37.9786 65.0256L37.1598 67.2108C36.9684 67.7219 37.2184 68.2868 37.7155 68.473C38.2127 68.6593 38.7723 68.3978 38.9638 67.8867L39.7584 65.7658C40.5042 63.7661 39.4627 61.6169 37.5159 60.8875Z"
          fill="#EFF4F8"
        />
        <path
          d="M30.1838 58.1409C28.3798 57.465 26.3511 58.4221 25.6562 60.2767C24.9613 62.1314 25.8618 64.1859 27.6658 64.8618C29.4697 65.5377 31.4985 64.5806 32.1934 62.726C32.8882 60.8713 31.9908 58.8179 30.1838 58.1409ZM28.3136 63.1326C27.4235 62.7991 26.9761 61.8008 27.3143 60.898C27.6526 59.9951 28.6459 59.5366 29.536 59.8701C30.426 60.2036 30.8735 61.2019 30.5353 62.1047C30.197 63.0076 29.2037 63.4661 28.3136 63.1326Z"
          fill="#EFF4F8"
        />
        <path
          d="M57.0076 41.4611L60.3929 32.1477C61.2463 30.0596 60.0252 27.7828 57.5753 26.8903L38.1822 19.8252C35.3894 18.8069 32.1356 19.9854 31.164 22.3671"
          fill="#0AF53D"
        />
        <path
          d="M57.0062 41.7472C57.0652 41.747 57.1226 41.7286 57.1707 41.6944C57.2188 41.6603 57.2552 41.6121 57.2748 41.5565L60.6601 32.2431C60.8577 31.7615 60.9553 31.2446 60.9468 30.7239C60.9382 30.2033 60.8238 29.6898 60.6105 29.2149C60.0924 28.0422 59.0484 27.1173 57.673 26.6196L38.2798 19.5545C35.3175 18.4752 31.9361 19.7128 30.8978 22.2566C30.8836 22.2914 30.8763 22.3287 30.8764 22.3663C30.8766 22.4039 30.8841 22.4412 30.8986 22.4759C30.9131 22.5106 30.9343 22.5421 30.961 22.5686C30.9876 22.5951 31.0193 22.6161 31.054 22.6304C31.0888 22.6446 31.1261 22.6519 31.1636 22.6518C31.2012 22.6517 31.2384 22.6441 31.2731 22.6296C31.3078 22.6151 31.3392 22.5939 31.3657 22.5672C31.3922 22.5405 31.4132 22.5088 31.4274 22.474C32.338 20.2448 35.447 19.1312 38.0836 20.0923L57.4768 27.1478C58.7055 27.5941 59.6332 28.4083 60.0885 29.4361C60.2727 29.8419 60.3712 30.2812 60.3781 30.7268C60.385 31.1723 60.3 31.6145 60.1286 32.0257L56.7338 41.362C56.7186 41.405 56.7139 41.4511 56.7201 41.4964C56.7264 41.5417 56.7434 41.5848 56.7697 41.6221C56.796 41.6595 56.8308 41.69 56.8713 41.7111C56.9118 41.7321 56.9568 41.7432 57.0024 41.7433L57.0062 41.7472Z"
          fill="#009420"
        />
        <path
          d="M7.10649 87.9356L36.5508 98.6572L36.3554 99.1948L6.91111 88.4732L7.10649 87.9356Z"
          fill="#0AF53D"
        />
        <path
          d="M19.3301 84.9811L19.8672 85.1768L12.5671 105.253L12.0301 105.058L19.3301 84.9811Z"
          fill="#0AF53D"
        />
        <path
          d="M28.8458 88.4496L29.3828 88.6453L22.0828 108.722L21.5457 108.526L28.8458 88.4496Z"
          fill="#0AF53D"
        />
        <path
          d="M14.9797 97.6304L34.4004 104.702L34.205 105.24L14.7844 98.1681L14.9797 97.6304Z"
          fill="#0AF53D"
        />
        <path
          d="M50.985 72.7827L55.5799 91.2702L55.9609 90.4502L51.5356 72.6454C51.5278 72.6077 51.5125 72.5719 51.4906 72.5403C51.4686 72.5087 51.4406 72.4818 51.408 72.4613C51.3755 72.4407 51.3392 72.427 51.3012 72.4209C51.2632 72.4148 51.2244 72.4164 51.1871 72.4256C51.1498 72.4349 51.1147 72.4515 51.0839 72.4747C51.0532 72.4978 51.0275 72.5269 51.0082 72.5603C50.989 72.5936 50.9767 72.6305 50.9721 72.6687C50.9674 72.7069 50.9705 72.7457 50.9812 72.7827H50.985Z"
          fill="#20252A"
        />
        <path
          d="M26.338 38.3774L27.6144 38.837L54.2676 48.4688V48.4078C54.5689 44.3252 55.3516 40.2927 56.5993 36.3942C58.1691 31.4191 60.3084 27.0866 62.4154 24.1118C71.1175 11.8332 80.7189 12.6417 84.9785 13.7725L51.1129 3.75171C39.0331 2.81542 26.1684 13.1432 26.338 38.3774Z"
          fill="#E6ECF2"
        />
        <path
          d="M54.4216 48.8686C54.4888 48.8685 54.5538 48.8447 54.6052 48.8014C54.6566 48.7582 54.6911 48.6981 54.7027 48.6319C54.7143 48.5657 54.7023 48.4975 54.6686 48.4393C54.635 48.3811 54.5819 48.3366 54.5188 48.3137L26.0901 37.9572C26.0205 37.9381 25.9462 37.946 25.8822 37.9795C25.8182 38.0129 25.7692 38.0694 25.7451 38.1375C25.721 38.2056 25.7235 38.2803 25.7521 38.3466C25.7808 38.4129 25.8335 38.4659 25.8996 38.495L54.3245 48.8514C54.3556 48.8628 54.3885 48.8686 54.4216 48.8686Z"
          fill="#20252A"
        />
      </g>
      <path
        d="M70.2788 78.973L66.8414 69.4588L57.3253 66.0272L66.8395 62.5917L70.2712 53.0737L73.7067 62.5879L83.2246 66.0196L73.7104 69.4569L70.2788 78.973Z"
        fill="#0AF53D"
      />
      <path
        d="M31.3406 19.0512L29.3009 13.4054L23.6551 11.3703L29.2994 9.33062L31.336 3.68481L33.3757 9.32908L39.0215 11.3657L33.3757 13.4054L31.3406 19.0512Z"
        fill="#0AF53D"
      />
      <path
        d="M21.4805 9.00612L20.3628 5.91187L17.2685 4.7972L20.3612 3.67945L21.4775 0.585205L22.5952 3.67791L25.6895 4.79412L22.5952 5.91187L21.4805 9.00612Z"
        fill="#0AF53D"
      />
      <defs>
        <clipPath id="clip0_4798_17084">
          <rect
            width="96"
            height="114.302"
            fill="white"
            transform="matrix(-1 0 0 1 96 3.69263)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TestimonialsMachine
